<template>
  <div>
    <!-- 
      평가계획 정보
       1. 평가명
       2. 평가기간
       3. 평가구분

       + 버튼을 통해 더 상세한 정보를 볼 수 있도록 처리
     -->
    <component
      :is="planInfoComponent"
      :assessPlan.sync="assessPlan"
      :disabled="disabled"
    />
    <!-- 
      대상공정별 대상정보 (테이블)
       - 대상정보 클릭 시 시나리오 정보로 이동
       - 시나리오 목록에서는 조회만, 입력은 클릭을 통해 팝업 open
       - 모든걸 입력 받지 않고 필수 값만
     -->
    <component
      :is="targetComponent"
      :assessPlan.sync="assessPlan"
      :disabled="disabled"
    />
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'riskDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        vendorFlag: false,
      }),
    },
  },
  data() {
    return {
      assessPlan: {
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        plantCd: '',  // 사업장 코드
        ramTechniqueCd: '',  // 평가기법 코드_HAZOP/KRAS/JSA/CHECKLIST
        ramAssessTypeCd: '',  // 평가구분_최초/정기/수시
        assessmentName: '',  // 평가명
        assessmentStartDate: '',  // 평가시작일
        assessmentEndDate: '',  // 평가종료일
        assessmentPeriod: [],
        assessmentYear: '',  // 평가년도
        ramStepCd: '',  // 평가 진행단계 코드
        assessmentManageDeptCd: '',  // 평가주관 부서 코드
        assessmentManageUserId: '',  // 평가주관 담당자 ID
        ramMatrixId: null,  // 위험메트릭스 번호
        sopMocId: '',  // MOC 일련번호
        remark: '',  // 상세내용
        workArea: '',  // 작업지역
        regDtStr: '',
        regUserId: '',  // 작성자 ID
        regUserName: '',  // 작성자 ID
        reviewUserId: '',  // 검토자 ID
        reviewDate: '',  // 검토일
        approvalUserId: '',  // 승인자 ID
        approvalDate: '',  // 승인일
        vendorCd: '',
        sopConstructionId: '',
        chgUserId: '',  // 수정자 ID
        teams: [], // 평가팀 목록
        occasions: [], // 수시 변경/추가 내역 목록
        conferences: [], // 회의 목록
        relatedDocuments: [], // 관련자료 목록
        processes: [], // 대상공정 목록
      },
      planInfoComponent: () => import(`${'@/pages/ram/plan/riskPlanInfo.vue'}`),
      targetComponent: () => import(`${'@/pages/ram/plan/riskTargetProcess.vue'}`),
      editable: true,
      detailUrl: '',
    };
  },
  computed: {
    disabled() {
      return this.assessPlan.ramStepCd === 'RRS0000015'
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.detailUrl = selectConfig.ram.assessPlan.get.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.ramRiskAssessmentPlanId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.ramRiskAssessmentPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.assessPlan, _result.data);
          this.assessPlan.assessmentPeriod = this.assessPlan.assessmentStartDate && this.assessPlan.assessmentEndDate ?
            [this.assessPlan.assessmentStartDate, this.assessPlan.assessmentEndDate] : []
        },);
      }
    },
    closePopUps() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '닫으시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$emit('closePopup')
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>
